import type { UseFetchOptions } from '#app'
import type { MaybeRefOrGetter } from 'vue'
import { defu } from 'defu'
import type {
  ApiOptionals,
  ApiResponse,
  ApiResponsePaginated,
  PaginationParams,
  UseApiFetchOptions,
} from '~/types/api.type'
import type { MappedOmit } from '~/types/common.type'
import type { NotificationThreshold } from '~/types/notification-threshold.type'
import type { Task } from '~/types/task.type'
import type { TaskList } from '~/types/taskList.type'

export interface ApiTaskGetListParams {
  projectId?: number
  taskListId?: number
  withTaskList?: boolean
  withProject?: boolean
  noPagination?: true
  additionalTasks?: Task['id'][]
}

export type ApiTaskGetListPaginatedParams = MappedOmit<ApiTaskGetListParams, 'noPagination'> & PaginationParams<Task> & {
  noPagination?: false
}

export interface ApiTaskGetByIdParams {
  withTimeEntries?: boolean
  withOtherParticipantsTimeEntries?: boolean
  withTaskList?: boolean
  withNotificationThresholds?: boolean
  withProject?: boolean
}

export type ApiTaskCreateTask = Pick<Task, 'name' | 'isCompleted'> & {
  taskListId: TaskList['id']
  notificationThresholds?: NotificationThreshold[]
  isBillable?: Task['isBillable']
  timeLimitSec?: Task['timeLimitSec']
}

export function useApiTaskGetList<T extends ApiTaskGetListParams>(params: MaybeRefOrGetter<T> = {} as T, options: UseApiFetchOptions<ApiResponse<Task<ApiOptionals<T>>[]>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<Task<ApiOptionals<T>>[]>> = { method: 'GET', params }
  return useApiFetch('/tasks', defu(notOverridableOptions, options))
}

export function useApiTaskGetListPaginated<T extends ApiTaskGetListPaginatedParams>(params: MaybeRefOrGetter<T> = {} as T, options: UseApiFetchOptions<ApiResponsePaginated<Task<ApiOptionals<T>>>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponsePaginated<Task<ApiOptionals<T>>>> = { method: 'GET', params }
  return useApiFetch('/tasks', defu(notOverridableOptions, options))
}

export function useApiTaskGetById<T extends ApiTaskGetByIdParams>(id: MaybeRefOrGetter<Task['id']>, params: MaybeRefOrGetter<T> = {} as T, options: UseFetchOptions<ApiResponse<Task<ApiOptionals<T>>>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<Task<ApiOptionals<T>>>> = { method: 'GET', params }
  return useApiFetch(computed(() => `/tasks/${toValue(id)}`), defu(notOverridableOptions, options))
}

export function useApiTaskCreate(task: MaybeRefOrGetter<ApiTaskCreateTask>, options: UseFetchOptions<ApiResponse<Task<undefined>>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<Task<undefined>>> = { method: 'POST', body: task }
  const overridableOptions: UseFetchOptions<ApiResponse<Task<undefined>>> = { immediate: false, watch: false }
  return useApiFetch('/tasks', defu(notOverridableOptions, options, overridableOptions))
}

export function useApiTaskUpdateById(id: MaybeRefOrGetter<Task['id']>, task: MaybeRefOrGetter<Partial<ApiTaskCreateTask>>, options: UseFetchOptions<ApiResponse<Task<undefined>>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<Task<undefined>>> = { method: 'PATCH', body: task }
  const overridableOptions: UseFetchOptions<ApiResponse<Task<undefined>>> = { immediate: false, watch: false }
  return useApiFetch(() => `/tasks/${toValue(id)}`, defu(notOverridableOptions, options, overridableOptions))
}

export function useApiTaskDeleteById(id: MaybeRefOrGetter<Task['id']>, options: UseFetchOptions<never> = {}) {
  const notOverridableOptions: UseFetchOptions<never> = { method: 'DELETE' }
  const overridableOptions: UseFetchOptions<never> = { immediate: false, watch: false }
  return useApiFetch(() => `/tasks/${toValue(id)}`, defu(notOverridableOptions, options, overridableOptions))
}
