import type { UseFetchOptions } from '#app'
import type { MaybeRefOrGetter } from 'vue'
import { defu } from 'defu'
import type {
  ApiOptionals,
  ApiResponse,
} from '~/types/api.type'
import type { Task } from '~/types/task.type'
import type { TaskList } from '~/types/taskList.type'

export type ApiTaskListGetListParams = {
  projectId?: number
  withCompleted?: boolean
  onlyCompleted?: boolean
  additionalTaskLists?: TaskList['id'][]
  additionalTasks?: Task['id'][]
} & ApiTaskListGetByIdParams

export interface ApiTaskListGetByIdParams {
  withProject?: boolean
  withTasks?: boolean
}

export type ApiTaskListCreateTaskList = Pick<TaskList, 'name' | 'projectId' | 'isCompleted'>

export function useApiTaskListGetList<T extends ApiTaskListGetListParams>(params: MaybeRefOrGetter<T> = {} as T, options: UseFetchOptions<ApiResponse<TaskList<ApiOptionals<T>>[]>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<TaskList<ApiOptionals<T>>[]>> = { method: 'GET', params }
  return useApiFetch('/task-lists', defu(notOverridableOptions, options))
}

export function useApiTaskListGetById<T extends ApiTaskListGetByIdParams>(id: MaybeRefOrGetter<TaskList['id']>, params: MaybeRefOrGetter<T> = {} as T, options: UseFetchOptions<ApiResponse<TaskList<ApiOptionals<T>>>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<TaskList<ApiOptionals<T>>>> = { method: 'GET', params: toValue(params) }
  return useApiFetch(() => `/task-lists/${toValue(id)}`, defu(notOverridableOptions, options))
}

export function useApiTaskListCreate(tasklist: MaybeRefOrGetter<ApiTaskListCreateTaskList>, options: UseFetchOptions<ApiResponse<TaskList<'withTasks'>>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<TaskList<'withTasks'>>> = { method: 'POST', body: toValue(tasklist) }
  const overridableOptions: UseFetchOptions<ApiResponse<TaskList<'withTasks'>>> = { immediate: false, watch: false }
  return useApiFetch('/task-lists', defu(notOverridableOptions, options, overridableOptions))
}

export function useApiTaskListUpdateById(id: MaybeRefOrGetter<TaskList['id']>, tasklist: MaybeRefOrGetter<Partial<TaskList>>, options: UseFetchOptions<ApiResponse<TaskList<'withTasks'>>> = {}) {
  const notOverridableOptions: UseFetchOptions<ApiResponse<TaskList<'withTasks'>>> = { method: 'PATCH', body: tasklist }
  const overridableOptions: UseFetchOptions<ApiResponse<TaskList<'withTasks'>>> = { immediate: false, watch: false }
  return useApiFetch(() => `/task-lists/${toValue(id)}`, defu(notOverridableOptions, options, overridableOptions))
}

export function useApiTaskListDeleteById(id: MaybeRefOrGetter<TaskList['id']>, options: UseFetchOptions<never> = {}) {
  const notOverridableOptions: UseFetchOptions<never> = { method: 'DELETE' }
  const overridableOptions: UseFetchOptions<never> = { immediate: false, watch: false }
  return useApiFetch(() => `/task-lists/${toValue(id)}`, defu(notOverridableOptions, options, overridableOptions))
}
