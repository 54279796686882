<script setup lang="ts">
import { NTooltip, type SelectFilter, type SelectGroupOption, type SelectOption, type SelectProps } from 'naive-ui'
import type { Project } from '~/types/project.type'
import type { Task } from '~/types/task.type'
import type { TaskList } from '~/types/taskList.type'

const props = defineProps({
  projectId: {
    type: Number as PropType<Project['id'] | null>,
    default: null,
  },
  taskLists: {
    type: Array as PropType<TaskList<'withTasks'>[]>,
    default: () => [],
  },
  selectProps: {
    type: Object as PropType<SelectProps>,
    default: undefined,
  },
  showTaskAddAction: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits<{
  taskCreated: [task: Task<undefined>]
}>()

const taskId = defineModel({
  type: Number as PropType<Task['id']>,
  default: undefined,
})

type TaskOption = SelectOption & { taskListName: TaskList['name'] }

const taskOptions = computed(() => {
  return props.taskLists.map<SelectGroupOption & { children: TaskOption[] }>((taskList) => {
    return {
      type: 'group',
      label: taskList.name,
      key: `${taskList.projectId}-${taskList.id}`,
      children: taskList.tasks.map((task) => {
        return {
          label: task.name,
          value: task.id,
          key: `${taskList.projectId}-${taskList.id}-${task.id}`,
          taskListName: taskList.name,
        }
      }),
    }
  })
})

function renderTaskOptionLabel(option: SelectOption) {
  return h(NTooltip, { placement: 'right', trigger: 'hover' }, {
    trigger: () => h('div', { class: 'text-truncate' }, {
      default: () => option.label,
    }),
    default: () => option.label,
  })
}

function patternMatched(pattern: string, value: string): boolean {
  try {
    return !!(
      1 + value.toString().toLowerCase().indexOf(pattern.trim().toLowerCase())
    )
  }
  catch {
    return false
  }
}

const filter: SelectFilter = (pattern, option) => {
  const taskOption = option as TaskOption
  const label = taskOption.label
  if (typeof label === 'string')
    return patternMatched(pattern, label) || patternMatched(pattern, taskOption.taskListName)

  return false
}

/* Start of task creation */

const taskNameInput = ref('')

const {
  openTaskCreateModal,
  isTaskCreateModalLoading,
} = useTaskActions()

async function openTaskCreationModal() {
  openTaskCreateModal({
    projectId: props.projectId!,
    taskName: taskNameInput.value,
    taskListsFilterToApply: {
      withCompleted: false,
    },
  }, (task) => {
    emit('taskCreated', task)
  })
}

/* End of task creation */
</script>

<template>
  <NSelect
    v-bind="selectProps"
    v-model:value="taskId"
    filterable
    placeholder="Bitte auswählen"
    :options="taskOptions"
    :filter="filter"
    :render-label="renderTaskOptionLabel"
    @search="taskNameInput = $event"
  >
    <template v-if="showTaskAddAction" #action>
      <NButton
        size="small"
        :loading="isTaskCreateModalLoading"
        @click="openTaskCreationModal"
      >
        <template #icon>
          <Icon name="i-ic-baseline-add" />
        </template>
        Neue Aufgabe hinzufügen
      </NButton>
    </template>
  </NSelect>
</template>
