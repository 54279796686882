<script setup lang="ts">
import type { CSSProperties } from 'vue'

const model = defineModel('value', {
  type: Boolean,
  default: false,
})

const invertedModel = computed({
  get() {
    return !model.value
  },
  set(open) {
    model.value = !open
  },
})

function railStyle({ checked, focused }: { checked: boolean, focused: boolean }) {
  const style: CSSProperties = {}
  if (!checked) {
    style.background = 'rgba(240, 160, 32, 0.6)'

    if (focused)
      style.boxShadow = '0 0 0 2px #f0a02040'
  }
  return style
}
</script>

<template>
  <NSwitch
    v-model:value="invertedModel"
    :rail-style="railStyle"
    size="large"
  >
    <template #checked>
      Offen
    </template>
    <template #unchecked>
      Abgeschlossen
    </template>
  </NSwitch>
</template>
