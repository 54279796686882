<script setup lang="ts">
import { unknownError } from '~/constants'
import type { Project } from '~/types/project.type'
import type { Task } from '~/types/task.type'
import type { TaskList } from '~/types/taskList.type'
import type { TimeTrackerFormModel } from '~/types/timeTracker.type'

const props = defineProps({
  projects: {
    type: Array as PropType<Array<Project<'withClient'>>>,
    default: () => [],
  },
  taskLists: {
    type: Array as PropType<Array<TaskList<'withTasks'>>>,
    default: undefined,
  },
})

const timeTracker = useTimeTracker()

const {
  isRunning,
  activeTimeEntry,
  timeTrackerModel,
  edited,
  timeEntryCreateError,
  timeEntryPendingError,
  timeEntryUpdateError,
  timeEntryStopError,
} = storeToRefs(timeTracker)

const message = useMessage()

/* Start of form handling */

const { formRef, rules, onSubmit, apiErrors } = useNaiveForm(timeTrackerModel)
const { apiErrorMessages, clearApiErrors } = useApiErrors<TimeTrackerFormModel>(
  apiErrors,
  timeEntryCreateError,
  timeEntryPendingError,
  timeEntryUpdateError,
  timeEntryStopError,
)

watch(() => timeTracker.isRunning, (isRunning) => {
  if (isRunning) {
    timeTrackerModel.value = {
      taskId: activeTimeEntry.value!.taskId,
      notes: activeTimeEntry.value!.notes,
      projectId: activeTimeEntry.value!.projectId,
      taskListId: activeTimeEntry.value!.task.taskListId,
    }
  }
  else {
    timeTrackerModel.value.notes = ''
  }

  timeTracker.resetModelState()
})

rules.value = {
  projectId: [
    {
      required: true,
      message: 'Bitte ein Projekt auswählen',
    },
    {
      validator: () => !apiErrors.value.projectId,
      message: () => apiErrorMessages.value.projectId!,
      trigger: ['input', 'blur'],
    },
  ],
  taskId: [
    {
      required: true,
      message: 'Bitte eine Aufgabe auswählen',
    },
    {
      validator: () => !apiErrors.value.taskId,
      message: () => apiErrorMessages.value.taskId!,
      trigger: ['input', 'blur'],
    },
  ],
}

async function submitForm() {
  clearApiErrors()

  if (!isRunning.value) {
    onSubmit(async () => {
      await timeTracker.start()
    })
  }
  else if (edited.value) {
    onSubmit(async () => {
      const { error } = await timeTracker.updateActiveTimeEntry({
        taskId: timeTrackerModel.value.taskId!,
        notes: timeTrackerModel.value.notes,
      })

      if (error.value)
        message.error(error.value?.data.message || unknownError)
      else
        timeTracker.refreshInitialModelState()
    })
  }
  else {
    await timeTracker.stop()
  }
}
/* End of form handling */

/* Start of projects */

function onProjectSelect() {
  timeTrackerModel.value.taskId = null // clear task selection due to new project
}
/* End of projects */

/* Start of tasks */

const taskListsFilter = computed<ApiTaskListGetListParams & { withTasks: true, withCompleted: false }>(() => {
  return {
    projectId: timeTrackerModel.value.projectId || 0,
    withTasks: true,
    withCompleted: false,
  }
})

const { data: taskListsData, status: tasksStatus, refresh: fetchTaskLists } = useApiTaskListGetList(taskListsFilter, { immediate: false })

const taskLists = computed(() => {
  return taskListsData.value?.data || props.taskLists || []
})

async function onTaskCreated(task: Task<undefined>) {
  await fetchTaskLists()
  timeTrackerModel.value.taskId = task.id
  formRef.value?.validate(undefined, rule => rule.key === 'taskId')
}
/* End of tasks */
</script>

<template>
  <NForm ref="formRef" :model="timeTrackerModel" :rules="rules" class="max-w-full w-md" @submit.prevent="submitForm">
    <NFormItem label="Project" path="projectId">
      <ProjectSelect
        v-model="timeTrackerModel.projectId"
        :projects="projects"
        show-recent-projects-group
        @update:value="onProjectSelect"
      />
    </NFormItem>

    <NFormItem label="Aufgabe" path="taskId">
      <TaskSelect
        v-model:value="timeTrackerModel.taskId"
        :project-id="timeTrackerModel.projectId"
        :task-lists="taskLists"
        :select-props="{
          loading: tasksStatus === 'pending',
          disabled: timeTrackerModel.projectId === null,
        }"
        :show-task-add-action="timeTrackerModel.projectId !== null"
        @task-created="onTaskCreated"
      />
    </NFormItem>

    <NFormItem label="Anmerkungen zum Zeiteintrag" path="notes">
      <NInput v-model:value="timeTrackerModel.notes" type="textarea" placeholder="Optionale Anmerkungen" :rows="8" :resizable="false" />
    </NFormItem>

    <Stopwatch v-if="!isRunning || !edited" />
    <div v-else class="flex items-center">
      <NButtonGroup>
        <NButton attr-type="submit" type="primary">
          Aktualisieren
        </NButton>
        <NButton @click="timeTracker.resetModelState">
          Abbrechen
        </NButton>
      </NButtonGroup>
    </div>
  </NForm>
</template>
