import { TaskListModal } from '#components'
import type { TaskList } from '~/types/taskList.type'

export default function () {
  const modal = useModal()
  const dialog = useDialog()
  const message = useMessage()
  const loadingBar = useNaiveLoadingBar()

  type TaskListModalProps = InstanceType<typeof TaskListModal>['$props']

  function openTaskListCreateModal(options: Pick<TaskListModalProps, 'project' | 'name'>, onTaskListCreated?: (taskList: TaskList<'withTasks'>) => any) {
    modal.create({
      title: 'Aufgabenliste erstellen',
      preset: 'dialog',
      showIcon: false,
      content: () => h(TaskListModal, {
        project: options.project,
        name: options.name,
        onTaskListCreated,
      }),
    })
  }

  const taskListToEditId = ref<TaskList['id']>(0)

  const {
    data: taskListToEditData,
    error: taskListToEditError,
    status: taskListToEditStatus,
    execute: fetchTaskListToEdit,
  } = useApiTaskListGetById(taskListToEditId, {
    withProject: true,
    withTasks: true,
  }, {
    immediate: false,
    watch: false,
  })

  const isAnyTaskListEditModalLoading = computed(() => taskListToEditStatus.value === 'pending')

  function isTaskListEditModalLoading(taskListId: TaskList['id']) {
    return computed(() => isAnyTaskListEditModalLoading.value && taskListToEditId.value === taskListId)
  }

  async function openTaskListEditModal(
    taskList: TaskList<undefined>,
    options: Pick<TaskListModalProps, 'project'>,
    onTaskListEdited?: (taskList: TaskList<'withTasks'>) => any,
    onTaskListDeleted?: () => any,
  ) {
    taskListToEditId.value = taskList.id
    await fetchTaskListToEdit()

    if (taskListToEditData.value && !taskListToEditError.value) {
      return modal.create({
        title: 'Aufgabenliste bearbeiten',
        preset: 'dialog',
        showIcon: false,
        content: () => h(TaskListModal, {
          project: options.project,
          taskList: taskListToEditData.value?.data,
          onTaskListEdited,
          onTaskListDeleted,
        }),
      })
    }
    else if (taskListToEditError.value) {
      message.error(taskListToEditError.value.data.message)
    }
  }

  function openTaskListDeleteDialog(taskList: TaskList<undefined>, onTaskListDeleted?: () => any) {
    return dialog.create({
      title: 'Wirklich löschen?',
      content: () => h('div', ['Bist du dir sicher, dass du die Aufgabenliste ', h('span', { class: 'font-semibold' }, taskList.name), ' löschen möchtest?']),
      positiveText: 'Löschen',
      negativeText: 'Nicht löschen',
      onPositiveClick: () => {
        deleteTaskList(taskList, onTaskListDeleted)
      },
    })
  }

  const taskListToDeleteId = ref<TaskList<undefined>['id']>(0)
  const { error: taskListDeleteError, execute: executeTaskListDelete } = useApiTaskListDeleteById(taskListToDeleteId)

  async function deleteTaskList(taskList: TaskList<undefined>, onTaskListDeleted?: () => any) {
    taskListToDeleteId.value = taskList.id
    loadingBar.start()
    await executeTaskListDelete()
    loadingBar.finish()

    if (!taskListDeleteError.value) {
      message.success('Aufgabenliste erfolgreich gelöscht!')

      if (onTaskListDeleted)
        onTaskListDeleted()
    }
    else {
      message.error(taskListDeleteError.value.data.message)
    }
  }

  const taskListUpdateCompletedParams = ref<Pick<TaskList<undefined>, 'isCompleted'>>({
    isCompleted: false,
  })

  const taskListIdToToggleCompleted = ref<TaskList<undefined>['id']>(0)

  const {
    data: taskListUpdateCompletedData,
    error: taskListUpdateCompletedError,
    execute: updateTaskListCompleted,
    status: taskListUpdateCompletedStatus,
  } = useApiTaskListUpdateById(taskListIdToToggleCompleted, taskListUpdateCompletedParams)

  const isAnyTaskListToggleCompletedLoading = computed(() => taskListUpdateCompletedStatus.value === 'pending')

  function isTaskListToggleCompletedLoading(taskListId: TaskList<undefined>['id']) {
    return computed(() => isAnyTaskListToggleCompletedLoading.value && taskListIdToToggleCompleted.value === taskListId)
  }

  function openTaskListToggleCompletedDialog(
    taskList: TaskList<undefined>,
    onToggleCompleted?: (isCompleted: boolean) => any,
  ) {
    if (taskList.isCompleted)
      openRestoreDialog(taskList, onToggleCompleted)
    else
      openArchiveDialog(taskList, onToggleCompleted)
  }

  function openRestoreDialog(
    taskList: TaskList<undefined>,
    onToggleCompleted?: (isCompleted: boolean) => any,
  ) {
    dialog.create({
      title: 'Wirklich wieder öffnen?',
      content: () => h('div', ['Bist du dir sicher, dass du die Aufgabenliste ', h('span', { class: 'font-semibold' }, taskList.name), ' wieder öffnen möchtest?']),
      positiveText: 'Wieder öffnen',
      negativeText: 'Nicht wieder öffnen',
      onPositiveClick: () => toggleTaskListCompleted(taskList, onToggleCompleted),
    })
  }

  function openArchiveDialog(
    taskList: TaskList<undefined>,
    onToggleCompleted?: (isCompleted: boolean) => any,
  ) {
    dialog.create({
      title: 'Wirklich abschließen?',
      content: () => h('div', ['Bist du dir sicher, dass du die Aufgabenliste ', h('span', { class: 'font-semibold' }, taskList.name), ' abschließen möchtest?']),
      positiveText: 'Abschließen',
      negativeText: 'Nicht abschließen',
      onPositiveClick: () => toggleTaskListCompleted(taskList, onToggleCompleted),
    })
  }

  async function toggleTaskListCompleted(
    taskList: TaskList<undefined>,
    onToggleCompleted?: (isCompleted: boolean) => any,
  ) {
    taskListIdToToggleCompleted.value = taskList.id
    taskListUpdateCompletedParams.value = {
      isCompleted: !taskList.isCompleted,
    }
    await updateTaskListCompleted()

    if (taskListUpdateCompletedData.value) {
      message.success(`Aufgabenliste erfolgreich ${taskList.isCompleted ? 'wieder geöffnet' : 'abgeschlossen'}!`)
      if (onToggleCompleted)
        onToggleCompleted(taskListUpdateCompletedData.value.data.isCompleted)
    }
    else if (taskListUpdateCompletedError.value) {
      message.error(taskListUpdateCompletedError.value.data.message)
    }
  }

  return {
    openTaskListCreateModal,
    isAnyTaskListEditModalLoading,
    isTaskListEditModalLoading,
    openTaskListEditModal,
    openTaskListDeleteDialog,
    openTaskListToggleCompletedDialog,
    isAnyTaskListToggleCompletedLoading,
    isTaskListToggleCompletedLoading,
  }
}
