import { TaskModal } from '#components'
import type { Project } from '~/types/project.type'
import type { Task } from '~/types/task.type'
import type { TaskList } from '~/types/taskList.type'

export default function () {
  const modal = useModal()
  const dialog = useDialog()
  const message = useMessage()
  const loadingBar = useNaiveLoadingBar()

  const taskListsFilter = ref<ApiTaskListGetListParams>({})

  const { data: taskListsData, execute: fetchTaskLists } = useApiTaskListGetList(taskListsFilter, {
    immediate: false,
    watch: false,
  })

  const { data: projectsData, execute: fetchProjects } = useApiProjectGetListUnpaginated({
    withTaskLists: true,
    withLeads: true,
    withClient: true,
  }, {
    immediate: false,
    watch: false,
  })

  const isTaskCreateModalLoading = ref(false)

  async function openTaskCreateModal(options: {
    taskListsFilterToApply: ApiTaskListGetListParams
    taskListId?: TaskList['id']
    projectId?: Project['id']
    taskName?: string
  } = {
    taskListsFilterToApply: {
      withCompleted: false,
    },
  }, onTaskCreated?: (task: Task<undefined>) => any) {
    loadingBar.start()
    isTaskCreateModalLoading.value = true
    const promises = [fetchProjects()]
    if (options.projectId) {
      taskListsFilter.value = {
        ...options.taskListsFilterToApply,
        projectId: options.projectId,
      }
      promises.push(fetchTaskLists())
    }
    await Promise.all(promises)
    loadingBar.finish()
    isTaskCreateModalLoading.value = false

    return modal.create({
      title: 'Aufgabe erstellen',
      showIcon: false,
      preset: 'dialog',
      class: '!w-3xl',
      content: () => h(TaskModal, {
        projects: projectsData.value?.data ?? undefined,
        projectId: options.projectId,
        taskListId: options.taskListId,
        taskLists: options.projectId ? taskListsData.value?.data : undefined,
        onTaskCreated,
      }),
    })
  }

  const taskToEditId = ref<Task['id']>(0)

  const {
    data: taskToEditData,
    execute: fetchTaskToEdit,
    error: taskEditError,
  } = useApiTaskGetById(taskToEditId, {
    withTaskList: true,
    withNotificationThresholds: true,
    withProject: true,
  }, {
    immediate: false,
    watch: false,
  })

  const isAnyTaskEditModalLoading = ref(false)

  function isTaskEditModalLoading(taskId: Task['id']) {
    return computed(() => isAnyTaskEditModalLoading.value && taskToEditId.value === taskId)
  }

  async function openTaskEditModal(
    task: Task<undefined>,
    options: {
      taskListsFilterToApply: ApiTaskListGetListParams
      projectId?: Project['id']
    } = { taskListsFilterToApply: {} },
    onTaskEdited?: (task: Task<undefined>) => any,
    onTaskDeleted?: () => any,
  ) {
    if (isAnyTaskEditModalLoading.value)
      return

    loadingBar.start()
    taskToEditId.value = task.id
    isAnyTaskEditModalLoading.value = true
    await fetchTaskToEdit()
    taskListsFilter.value = {
      ...options.taskListsFilterToApply,
      projectId: taskToEditData.value?.data.taskList.projectId || options.projectId,
    }
    await Promise.all([fetchTaskLists(), fetchProjects()])
    loadingBar.finish()
    isAnyTaskEditModalLoading.value = false

    if (!taskEditError.value) {
      return modal.create({
        title: `Aufgabe bearbeiten: ${task.name}`,
        showIcon: false,
        preset: 'dialog',
        class: '!w-3xl',
        content: () => h(TaskModal, {
          task: taskToEditData.value?.data ?? undefined,
          projectId: options.projectId,
          projects: projectsData.value?.data ?? undefined,
          taskLists: taskListsData.value?.data ?? undefined,
          onTaskEdited,
          onTaskDeleted,
        }),
      })
    }
    else {
      message.error(taskEditError.value.data.message)
    }
  }

  const taskToDeleteId = ref<Task['id']>(0)
  const { error: taskDeleteError, execute: executeDelete } = useApiTaskDeleteById(taskToDeleteId)

  function openTaskDeleteDialog(task: Task<undefined>, onTaskDeleted?: () => any) {
    return dialog.create({
      title: 'Wirklich löschen?',
      content: () => h('div', ['Bist du dir sicher, dass du die Aufgabe ', h('span', { class: 'font-semibold' }, task.name), ' löschen möchtest?']),
      positiveText: 'Löschen',
      negativeText: 'Nicht löschen',
      onPositiveClick: () => {
        deleteTask(task, onTaskDeleted)
      },
    })
  }

  async function deleteTask(task: Task<undefined>, onTaskDeleted?: () => any) {
    loadingBar.start()
    taskToDeleteId.value = task.id
    await executeDelete()
    loadingBar.finish()

    if (!taskDeleteError.value) {
      message.success('Aufgabe erfolgreich gelöscht!')

      if (onTaskDeleted)
        onTaskDeleted()
    }
    else {
      message.error(taskDeleteError.value.data.message)
    }
  }

  return {
    isTaskCreateModalLoading,
    openTaskCreateModal,
    isAnyTaskEditModalLoading,
    openTaskEditModal,
    isTaskEditModalLoading,
    openTaskDeleteDialog,
  }
}
