<script setup lang="ts">
defineProps({
  type: {
    type: String as PropType<'form' | 'tracker'>,
    default: 'form',
  },
  collapsed: {
    type: Boolean,
    default: false,
  },
})

const timeTracker = useTimeTracker()

const { isRunning, timeElapsed, loading } = storeToRefs(timeTracker)

const currentTime = computed(() => secondsToTime(Math.floor(timeElapsed.value / 1000)))

function secondsToTime(seconds: number) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
}
</script>

<template>
  <div class="h-fit flex items-center" :class="{ 'justify-between': type === 'form' }">
    <template v-if="!isRunning">
      <NButton v-if="type === 'form'" :loading="loading" attr-type="submit" secondary size="large" strong type="primary">
        <template #icon>
          <Icon
            name="ic:round-play-circle"
          />
        </template>
        Start
      </NButton>
      <NButton v-else :loading="loading" size="large" text round type="primary" :theme-overrides="{ iconSizeLarge: '32px', paddingRoundLarge: '10px' }" @click.stop="timeTracker.start()">
        <template #icon>
          <Icon
            name="ic:round-play-circle"
          />
        </template>
      </NButton>
    </template>
    <template v-else>
      <NButton v-if="type === 'form'" :loading="loading" attr-type="submit" secondary size="large" strong type="error">
        <template #icon>
          <Icon
            name="ic:round-stop-circle"
          />
        </template>
        Stop
      </NButton>
      <template v-else>
        <NButton :loading="loading" class="hidden group-hover:inline-flex" size="large" text round type="error" :theme-overrides="{ iconSizeLarge: '32px', paddingRoundLarge: '10px' }" @click.stop="timeTracker.stop">
          <template #icon>
            <Icon
              name="ic:round-stop-circle"
              class="hidden group-hover:inline-block"
            />
          </template>
        </NButton>
        <NButton :loading="loading" class="group-hover:hidden" size="large" text round type="success" :theme-overrides="{ iconSizeLarge: '32px', paddingRoundLarge: '10px' }" @click.stop="timeTracker.stop">
          <template #icon>
            <Icon
              name="ic:round-access-alarm"
            />
          </template>
        </NButton>
      </template>
    </template>
    <div v-if="!collapsed" class="ml-2">
      <h2 class="text-lg font-semibold">
        {{ currentTime }}
      </h2>
    </div>
  </div>
</template>
