<script setup lang="ts">
import type { SelectOption } from 'naive-ui'
import { NFormItem } from '#components'
import { subject } from '@casl/ability'
import { NButton, NButtonGroup, NForm, NInput, NInputNumber, NSelect, NSwitch, useMessage, useModalReactiveList } from 'naive-ui'
import type { ApiTaskCreateTask } from '~/composables/api/modules/task'
import { useApiTaskCreate } from '~/composables/api/modules/task'

import { type ApiTaskListGetListParams, useApiTaskListGetList } from '~/composables/api/modules/tasklist'
import type { TaskListSubject, TaskSubject } from '~/types/authorization.type'
import type { NotificationThreshold } from '~/types/notification-threshold.type'
import type { Project } from '~/types/project.type'
import type { Task } from '~/types/task.type'
import type { TaskList } from '~/types/taskList.type'

const props = defineProps({
  task: {
    type: Object as PropType<Task<'withTaskList' | 'withProject' | 'withNotificationThresholds'>>,
    default: undefined,
  },
  projects: {
    type: Array as PropType<Project<'withTaskLists' | 'withLeads' | 'withClient'>[]>,
    default: () => [],
  },
  taskLists: {
    type: Array as PropType<TaskList<undefined>[]>,
    default: () => [],
  },
  projectId: {
    type: Number as PropType<Project['id']>,
    default: undefined,
  },
  taskName: {
    type: String as PropType<Task['name']>,
    default: '',
  },
  taskListId: {
    type: Number as PropType<TaskList['id']>,
    default: undefined,
  },
})

const emit = defineEmits<{
  taskCreated: [task: Task<undefined>]
  taskEdited: [task: Task<undefined>]
  taskDeleted: []
}>()

const message = useMessage()
const modals = useModalReactiveList()
const { can } = useAppAbility()

const modal = computed(() => {
  return modals.value.length ? modals.value[modals.value.length - 1] : undefined
})

type TaskFormState = Pick<ApiTaskCreateTask, 'name' | 'timeLimitSec' | 'isBillable' | 'notificationThresholds' | 'isCompleted'> & {
  taskListId: Task['taskListId'] | null
  projectId: number | null
  timeLimitHrs: number
  notificationThresholds: NotificationThreshold[]
}

const taskListNameInput = ref<string>('')

const model = ref<TaskFormState>({
  taskListId: props.task?.taskListId || props.taskListId || null,
  isBillable: !!props.task?.isBillable,
  name: props.task?.name || props.taskName,
  projectId: props.task?.taskList.projectId || props.projectId || null,
  timeLimitHrs: (props.task?.timeLimitSec || 0) / 3600,
  timeLimitSec: (props.task?.timeLimitSec || 0) % 3600,
  notificationThresholds: (props.task?.notificationThresholds || []).map((threshold) => {
    return {
      ...threshold,
      value: threshold.value * 100,
    }
  }),
  isCompleted: props.task?.isCompleted || false,
})

const { edited, reset } = useFormModel(model)

const taskFetchModel = ref<ApiTaskCreateTask>({} as ApiTaskCreateTask)

const { execute: createTaskFetch, error: createError, data: createTaskData } = useApiTaskCreate(taskFetchModel)
const { execute: editTaskFetch, error: editError, data: editTaskData } = useApiTaskUpdateById(props.task?.id || 0, taskFetchModel)

const { formRef, rules, onSubmit, pending, apiErrors } = useNaiveForm(model)

const { apiErrorMessages, clearApiErrors } = useApiErrors<ApiTaskCreateTask>(apiErrors, createError, editError)

rules.value = {
  name: [
    {
      required: true,
      message: 'Bitte einen Titel eingeben',
      trigger: ['input', 'blur'],
      level: 'error',
    },
    {
      validator: () => !apiErrors.value.name,
      message: () => apiErrorMessages.value.name!,
      trigger: ['input', 'blur'],
    },
  ],
  timeLimitHrs: [
    {
      required: false,
      type: 'number',
    },
    {
      validator: () => !apiErrors.value.timeLimitSec,
      message: () => apiErrorMessages.value.timeLimitSec!,
      trigger: ['input', 'blur'],
    },
  ],
  projectId: [
    {
      required: true,
      validator: (_rule, val) => {
        if (typeof val !== 'number')
          return new Error('Bitte ein Projekt auswählen')

        return true
      },
      trigger: ['input', 'blur'],
    },
  ],
  taskListId: [
    {
      required: true,
      key: 'taskListId',
      validator: (_rule, val) => {
        if (typeof val !== 'number')
          return new Error('Bitte eine Aufgabenliste auswählen')

        return true
      },
      trigger: ['input', 'blur'],
    },
    {
      key: 'taskListId',
      validator: () => !apiErrors.value.taskListId,
      message: () => apiErrorMessages.value.taskListId!,
      trigger: ['input', 'blur'],
    },
  ],
  isBillable: [
    {
      validator: () => !apiErrors.value.isBillable,
      message: () => apiErrorMessages.value.isBillable!,
      trigger: ['input', 'blur'],
    },
  ],
  notificationThresholds: {
    key: 'notificationThresholds',
    validator: () => !apiErrors.value.notificationThresholds,
    message: () => apiErrorMessages.value.notificationThresholds!,
    trigger: ['input', 'blur'],
  },
}

const selectedProject = computed(() => {
  return props.projects.find(project => project.id === model.value.projectId)
})

watch(() => selectedProject.value?.billingType, (billingType) => {
  if (billingType === 'fixed')
    model.value.isBillable = false // set isBillable to false if new project doesn't offer that option
  else if (!props.task)
    model.value.isBillable = true // set isBillable to true if new project offer that option and it's a new task
}, { immediate: true })

const canCreateTaskList = computed(() => {
  return can('create', subject('task-list', {
    project: selectedProject.value,
  } as TaskListSubject))
})

const taskListRequestParams = computed<ApiTaskListGetListParams>(() => {
  return {
    projectId: model.value.projectId ?? undefined,
  }
})

const { data: taskListsData, refresh: fetchTaskLists, status: taskListsStatus } = useApiTaskListGetList(taskListRequestParams, {
  immediate: typeof model.value.projectId === 'number', // only fetch if a project is selected beforehand
})

async function switchProject() {
  model.value.taskListId = null // clear the tasklist selection on project switch
}

const taskLists = computed<TaskList<undefined>[]>(() => {
  return taskListsData.value ? taskListsData.value.data : props.taskLists
})

const taskListOptions = computed<SelectOption[]>(() => {
  return taskLists.value.map((taskList) => {
    return {
      label: taskList.name,
      value: taskList.id,
    }
  })
})

function convertThresholdValue(threshold: NotificationThreshold) {
  return {
    ...threshold,
    value: threshold.value / 100,
  }
}

const canSetBillable = computed(() => {
  if (!selectedProject.value)
    return false
  return can('update', subject('task', { project: selectedProject.value }), 'isBillable') && selectedProject.value.billingType === 'times_and_material'
})

const canSetTimeLimit = computed(() => {
  if (!selectedProject.value)
    return false
  return can('update', subject('task', { project: selectedProject.value }), 'timeLimitSec')
})

const canSetCompleted = computed(() => {
  if (!selectedProject.value)
    return false
  return can('update', subject('task', { project: selectedProject.value }), 'isCompleted')
})

const canSetNotificationThresholds = computed(() => {
  if (!selectedProject.value)
    return false
  return can('update', subject('task', { project: selectedProject.value }), 'notificationThresholds')
})

const canDeleteTask = computed(() => {
  if (!props.task)
    return false
  return can('delete', subject('task', { project: props.task.taskList.project } as TaskSubject))
})

function submitForm() {
  clearApiErrors()
  onSubmit(() => props.task ? editTask() : createTask())
}

async function createTask() {
  taskFetchModel.value = {
    name: model.value.name,
    isBillable: canSetBillable.value ? model.value.isBillable : undefined,
    taskListId: model.value.taskListId || 0,
    timeLimitSec: canSetTimeLimit.value ? model.value.timeLimitHrs * 3600 : undefined,
    notificationThresholds: canSetNotificationThresholds.value ? model.value.notificationThresholds.map(convertThresholdValue) : undefined,
    isCompleted: model.value.isCompleted,
  }
  await createTaskFetch()

  if (!createError.value && createTaskData.value) {
    emit('taskCreated', createTaskData.value.data)
    message.success('Aufgabe erfolgreich erstellt!', {
      closable: true,
      duration: 3500,
    })
    modal.value?.destroy()
  }
  else {
    if (createError.value)
      message.error(createError.value.data.message)
    else
      message.error('Aufgabe konnte nicht erstellt werden!')
  }
}

async function editTask() {
  taskFetchModel.value = {
    name: model.value.name,
    isBillable: canSetBillable.value ? model.value.isBillable : undefined,
    taskListId: model.value.taskListId || 0,
    timeLimitSec: canSetTimeLimit.value ? model.value.timeLimitHrs * 3600 : undefined,
    notificationThresholds: canSetNotificationThresholds.value ? model.value.notificationThresholds.map(convertThresholdValue) : undefined,
    isCompleted: model.value.isCompleted,
  }
  await editTaskFetch()

  if (!editError.value && editTaskData.value) {
    emit('taskEdited', editTaskData.value.data)
    message.success('Aufgabe erfolgreich aktualisiert!')
    modal.value?.destroy()
  }
  else {
    if (editError.value)
      message.error(editError.value.data.message)
    else
      message.error('Aufgabe konnte nicht aktualisiert werden!')
  }
}

const {
  openTaskListCreateModal,
} = useTaskListActions()

async function openTaskListCreationModal() {
  if (!selectedProject.value)
    return

  openTaskListCreateModal({
    name: taskListNameInput.value,
    project: selectedProject.value!,
  }, async (taskList) => {
    await fetchTaskLists()
    model.value.taskListId = taskList.id
    formRef.value?.validate(undefined, (rule) => {
      return rule.key === 'taskListId'
    })
  })
}

const {
  openTaskDeleteDialog,
} = useTaskActions()

/* Start of grid related vars */

const gridRowsCount = computed(() => {
  let leftRows = 1
  let rightRows = 1
  if (props.projectId === undefined)
    rightRows++
  if (canSetNotificationThresholds.value && model.value.timeLimitHrs !== 0)
    rightRows++
  if (canSetTimeLimit.value)
    rightRows++
  if (canSetBillable.value)
    leftRows++
  if (canSetCompleted.value)
    leftRows++
  return Math.max(leftRows, rightRows)
})

const rowSpan = computed(() => {
  switch (gridRowsCount.value) {
    case 2:
      return 'row-span-2'
    case 3:
      return 'row-span-3'
    case 4:
      return 'row-span-4'
    default:
      return 'row-span-1'
  }
})

/* End of grid related vars */
</script>

<template>
  <NForm ref="formRef" :model="model" :rules="rules" @submit.prevent="submitForm()">
    <div class="grid auto-rows-auto grid-cols-1 gap-x-6 md:grid-cols-[1fr_1fr]">
      <div class="grid grid-rows-subgrid items-start" :class="rowSpan">
        <NFormItem label="Aufgabenbezeichnung" path="name">
          <NInput
            v-model:value="model.name"
            placeholder="Beispielaufgabe"
          />
        </NFormItem>
        <NFormItem v-if="canSetCompleted" label="Status" path="isCompleted">
          <TaskCompletedSwitch
            v-model:value="model.isCompleted"
          />
        </NFormItem>
        <NFormItem v-if="canSetBillable" label="Abrechnungsart" path="isBillable">
          <NSwitch
            v-model:value="model.isBillable"
            size="large"
            :disabled="!selectedProject || selectedProject.billingType === 'fixed'"
          >
            <template #checked>
              Abrechenbar
            </template>
            <template #unchecked>
              Nicht abrechenbar
            </template>
          </NSwitch>
        </NFormItem>
      </div>
      <div class="grid grid-rows-subgrid items-start" :class="rowSpan">
        <NFormItem v-if="props.projectId === undefined" label="Projekt" path="projectId">
          <ProjectSelect
            v-model="model.projectId"
            :projects="projects"
            @update:value="switchProject"
          />
        </NFormItem>
        <NFormItem label="Aufgabenliste" path="taskListId">
          <NSelect
            v-model:value="model.taskListId"
            filterable
            :options="taskListOptions"
            :disabled="!model.projectId"
            placeholder="Bitte auswählen"
            :loading="taskListsStatus === 'pending'"
            @search="taskListNameInput = $event"
          >
            <template v-if="canCreateTaskList" #action>
              <NButton size="small" @click="openTaskListCreationModal">
                <template #icon>
                  <Icon name="i-ic-baseline-add" />
                </template>
                Neue Aufgabenliste hinzufügen
              </NButton>
            </template>
          </NSelect>
        </NFormItem>
        <NFormItem v-if="canSetTimeLimit" label="Zeitlimit (Std)" path="timeLimitHrs">
          <NInputNumber
            v-model:value="model.timeLimitHrs"
            :min="0"
            class="w-full"
            placeholder="Zeitlimit in Minuten"
          />
        </NFormItem>
        <NFormItem v-if="model.timeLimitHrs !== 0 && canSetNotificationThresholds" label="Benachrichtigung bei Schwellwerten" path="notificationThresholds">
          <NotificationThresholdSelection v-model="model.notificationThresholds" />
        </NFormItem>
      </div>
    </div>
    <div class="flex justify-end">
      <NButtonGroup>
        <NButton
          type="success" :loading="pending"
          :disabled="pending || !edited" attr-type="submit"
        >
          {{ `Aufgabe ${task ? 'aktualisieren' : 'erstellen'}` }}
        </NButton>
        <NButton secondary :disabled="task && !edited" @click="() => task ? reset() : modal?.destroy()">
          {{ task ? 'Zurücksetzen' : 'Abbrechen' }}
        </NButton>
        <NButton v-if="canDeleteTask" secondary type="error" @click="openTaskDeleteDialog(task!, () => (modal?.destroy(), emit('taskDeleted')))">
          Löschen
        </NButton>
      </NButtonGroup>
    </div>
  </NForm>
</template>
