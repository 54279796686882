<script setup lang="ts">
import { subject } from '@casl/ability'
import { useModalReactiveList } from 'naive-ui'
import type { TaskListSubject } from '~/types/authorization.type'
import type { Project } from '~/types/project.type'
import type { TaskList } from '~/types/taskList.type'

const props = defineProps({
  project: {
    type: Object as PropType<Project<'undefined'>>,
    required: true,
  },
  taskList: {
    type: Object as PropType<TaskList<'withTasks' | 'withProject'>>,
    default: undefined,
  },
  name: {
    type: String,
    default: undefined,
  },
})

const emit = defineEmits<{
  taskListCreated: [taskList: TaskList<'withTasks'>]
  taskListEdited: [taskList: TaskList<'withTasks'>]
  taskListDeleted: []
}>()

const model = ref<ApiTaskListCreateTaskList>({
  name: props.taskList?.name || props.name || '',
  projectId: props.project.id,
  isCompleted: props.taskList?.isCompleted || false,
})

const modals = useModalReactiveList()

const modal = computed(() => {
  return modals.value.length ? modals.value[modals.value.length - 1] : undefined
})

const { can } = useAppAbility()

const canDeleteTaskList = computed(() => {
  if (!props.taskList)
    return false
  return can('delete', subject('task-list', props.taskList))
})

const message = useMessage()
const loadingBar = useNaiveLoadingBar()

const { execute: executeTaskListCreate, error: taskListCreateError, data: taskListCreateData } = useApiTaskListCreate(model)

async function createTaskList() {
  loadingBar.start()
  await executeTaskListCreate()
  loadingBar.finish()

  if (!taskListCreateError.value && taskListCreateData.value) {
    emit('taskListCreated', taskListCreateData.value.data)
    message.success('Aufgabenliste erfolgreich erstellt!')
    modal.value?.destroy()
  }
  else if (taskListCreateError.value) {
    message.error(taskListCreateError.value.data.message)
  }
}

const { execute: executeTaskListEdit, error: taskListEditError, data: taskListEditData } = useApiTaskListUpdateById(props.taskList?.id || 0, model)

const { formRef, edited, reset, onSubmit, rules, pending, apiErrors } = useNaiveForm(model)
const { apiErrorMessages, clearApiErrors } = useApiErrors<ApiTaskListCreateTaskList>(apiErrors, taskListCreateError, taskListEditError)

rules.value = {
  name: [
    {
      required: true,
      message: 'Bitte einen Namen angeben',
      trigger: ['input', 'blur'],
    },
    {
      validator: () => !apiErrors.value.name,
      message: () => apiErrorMessages.value.name!,
      trigger: ['input', 'blur'],
    },
  ],
}

async function editTaskList() {
  loadingBar.start()
  await executeTaskListEdit()
  loadingBar.finish()

  if (!taskListEditError.value && taskListEditData.value) {
    emit('taskListEdited', taskListEditData.value.data)
    message.success('Aufgabeliste erfolgreich aktualisiert!')
    modal.value?.destroy()
  }
  else if (taskListEditError.value) {
    message.error(taskListEditError.value.data.message)
  }
}

function submitForm() {
  clearApiErrors()
  onSubmit(() => props.taskList ? editTaskList() : createTaskList())
}

const { openTaskListDeleteDialog } = useTaskListActions()
</script>

<template>
  <NForm ref="formRef" :model="model" :rules="rules" @submit.prevent="submitForm()">
    <NFormItem path="name" label="Name">
      <NInput v-model:value="model.name" placeholder="Aufgabenliste benennen" />
    </NFormItem>
    <NFormItem
      v-if="!taskList || can('update', subject('task-list', { project } as TaskListSubject), 'isCompleted')"
      label="Status"
      path="isCompleted"
    >
      <TaskListCompletedSwitch
        v-model:value="model.isCompleted"
      />
    </NFormItem>
    <NFormItem>
      <NButtonGroup>
        <NButton
          type="success" :loading="pending"
          :disabled="pending || (taskList && !edited)" attr-type="submit"
        >
          {{ `Aufgabenliste ${taskList ? 'aktualisieren' : 'erstellen'}` }}
        </NButton>
        <NButton secondary :disabled="taskList && !edited" @click="() => taskList ? reset() : modal?.destroy()">
          {{ taskList ? 'Zurücksetzen' : 'Abbrechen' }}
        </NButton>
        <NButton v-if="canDeleteTaskList" secondary type="error" @click="openTaskListDeleteDialog(taskList!, () => (modal?.destroy(), emit('taskListDeleted')))">
          Löschen
        </NButton>
      </NButtonGroup>
    </NFormItem>
  </NForm>
</template>
