<script setup lang="ts">
import type { SelectFilter, SelectGroupOption, SelectOption } from 'naive-ui'
import type { Client } from '~/types/client.type'
import type { Project } from '~/types/project.type'

const props = defineProps({
  projects: {
    type: Array as PropType<Array<Project<'withClient'>>>,
    default: () => [],
  },
  showRecentProjectsGroup: {
    type: Boolean,
    default: false,
  },
})

const project = defineModel<Project['id'] | null>({ default: undefined })
const recent = ref(false)

const selectModel = computed<string | Project['id'] | null>({
  get() {
    return recent.value ? `recent-${project.value}` : project.value
  },
  set(projectId) {
    if (typeof projectId === 'string') {
      recent.value = true
      project.value = Number.parseInt(projectId.replace('recent-', ''))
    }
    else {
      recent.value = false
      project.value = projectId
    }
  },
})

interface ProjectGroupAccumulator { [clientId: number]: SelectGroupOption & { children: ProjectOption[] } }
type ProjectOption = SelectOption & { clientName: Client['name'] }

const projectOptions = computed(() => {
  const initialGroups: Array<SelectGroupOption & { children: ProjectOption[] }> = []

  if (props.showRecentProjectsGroup) {
    initialGroups.push({
      key: 'recent',
      type: 'group',
      label: 'Zuletzt verwendet',
      children: props.projects.slice(0, 5).map<ProjectOption>((project) => {
        return {
          key: `recent-${project.id}`,
          label: `${project.name} (${project.client.name})`,
          value: `recent-${project.id}`,
          clientName: project.client.name,
        }
      }),
    })
  }

  return initialGroups.concat(Object.values(props.projects.reduce((acc, project) => {
    if (!acc[project.client.id]) {
      acc[project.client.id] = {
        type: 'group',
        label: project.client.name,
        key: `client-${project.client.id}`,
        children: [],
      }
    }

    acc[project.client.id].children?.push({
      label: project.name,
      value: project.id,
      clientName: project.client.name,
    })

    return acc
  }, {} as ProjectGroupAccumulator)))
})

const filter: SelectFilter = (pattern, option) => {
  const projectOption = option as ProjectOption
  const label = projectOption.label
  if (typeof label === 'string')
    return patternMatched(pattern, label) || patternMatched(pattern, projectOption.clientName)

  return false
}
</script>

<template>
  <NSelect
    v-model:value="selectModel"
    :options="projectOptions"
    placeholder="Projekt auswählen"
    filterable
    :filter="filter"
  />
</template>
