import type FullCalendar from '@fullcalendar/vue3'
import type { ModalOptions, ModalReactive } from 'naive-ui'
import type { ApiTimeEntryCreatePendingParams, ApiTimeEntryUpdateParams } from '../composables/api/modules/time-entry'
import { Icon, TimeEntryModal, TimeTrackerModal } from '#components'
import type { Project } from '~/types/project.type'
import type { Task } from '~/types/task.type'
import type { TimeEntry } from '~/types/timeEntry.type'
import type { TimeTrackerFormModel } from '~/types/timeTracker.type'

export const useTimeTracker = defineStore('time-tracker', () => {
  const message = useMessage()
  const activeCalendar = ref<InstanceType<typeof FullCalendar>>()

  const { data: initialTimeEntry, execute: fetchPending, error: timeEntryPendingError } = useApiTimeEntryGetPending({ watch: false, immediate: false })

  const activeTimeEntry = ref<TimeEntry<'withTask' | 'withProject'>>()
  const activeTimeEntryId = computed(() => {
    return activeTimeEntry.value?.id ?? 0
  })

  /* Start of time tracker model handling */
  const latestTimeEntry = useLatestTimeEntry()
  const latestProject = ref<Project<undefined>>()
  const latestTask = ref<Task<undefined>>()
  const initialProjectId = activeTimeEntry.value ? activeTimeEntry.value.projectId : latestTimeEntry.value.projectId
  const initialTaskListId = activeTimeEntry.value ? activeTimeEntry.value.task.taskListId : latestTimeEntry.value.taskListId
  const initialTaskId = activeTimeEntry.value ? activeTimeEntry.value.taskId : latestTimeEntry.value.taskId
  const recentTasks = useRecentTasks()

  const timeTrackerModel = ref<TimeTrackerFormModel>({
    projectId: initialProjectId || null,
    taskId: initialTaskId || null,
    taskListId: initialTaskListId || null,
    notes: '',
  })

  const { reset: resetModelState, refresh: refreshInitialModelState, edited } = useFormModel(timeTrackerModel)

  /* End of time tracker model handling */

  /* Start of modal */
  const modal = useModal()
  const loadingBar = useNaiveLoadingBar()
  const modalInstance = ref<ModalReactive>()

  const projectsFetchParams = computed<ApiProjectGetListParamsUnPaginated & {
    withClient: true
    isActive: true
    withLeads: true
  }>(() => {
    return {
      isActive: true,
      withLeads: true,
      withClient: true,
      additionalProjects: timeTrackerModel.value.projectId ? [timeTrackerModel.value.projectId] : undefined,
    }
  })

  const { data: projectsData, execute: fetchProjects } = useApiProjectGetListUnpaginated(projectsFetchParams, {
    immediate: false,
    watch: false,
  })

  const taskListsFetchParams = computed<ApiTaskListGetListParams & { withTasks: true, withCompleted: false }>(() => {
    return {
      withTasks: true,
      projectId: timeTrackerModel.value.projectId || undefined,
      withCompleted: false,
      additionalTaskLists: timeTrackerModel.value.taskListId ? [timeTrackerModel.value.taskListId] : undefined,
    }
  })

  const { data: taskListsData, execute: fetchTaskLists } = useApiTaskListGetList(taskListsFetchParams, { immediate: false, watch: false })

  const { data: employeesData, execute: fetchEmployees } = useApiEmployeeGetList({}, { immediate: false, watch: false })

  async function openModal(additionalModalOptions?: ModalOptions) {
    loadingBar.start()
    const fetches = [fetchProjects()]
    if (timeTrackerModel.value.projectId)
      fetches.push(fetchTaskLists())
    await Promise.all(fetches)
    loadingBar.finish()

    const projects = projectsData.value?.data || []
    if (!projects.find(project => project.id === timeTrackerModel.value.projectId))
      timeTrackerModel.value.projectId = null

    const taskLists = taskListsData.value?.data || []
    if (!taskLists.find(taskList => !!taskList.tasks.find(task => task.id === timeTrackerModel.value.taskId)))
      timeTrackerModel.value.taskId = null

    modalInstance.value = modal.create({
      icon: () => h('div', { class: 'text-xl h-full flex items-center' }, h(Icon, { name: 'ic:round-alarm-add' })),
      preset: 'dialog',
      title: 'Zeitgeber',
      content: () => h(TimeTrackerModal, {
        projects,
        taskLists: taskListsData.value?.data,
      }),
      onClose: closeModal,
      onEsc: closeModal,
      maskClosable: false,
      ...additionalModalOptions,
    })
  }

  function closeModal() {
    if (modalInstance.value) {
      modalInstance.value.destroy()
      modalInstance.value = undefined
    }
  }

  /* Enf of modal */

  /* Start of fetching */
  const timeEntryToCreate = ref<ApiTimeEntryCreatePendingParams>({} as ApiTimeEntryCreatePendingParams)
  const activeTimeEntryUpdate = ref<ApiTimeEntryUpdateParams>({} as ApiTimeEntryUpdateParams)

  const { data: timeEntryCreateData, error: timeEntryCreateError, execute: createTimeEntry, status: timeEntryCreateStatus } = useApiTimeEntryCreatePending(timeEntryToCreate)
  const { data: timeEntryUpdateData, error: timeEntryUpdateError, execute: updateTimeEntry } = useApiTimeEntryUpdateById(activeTimeEntryId, activeTimeEntryUpdate)
  const { data: timeEntryStopData, error: timeEntryStopError, execute: stopPendingTimeEntry, status: timeEntryStopStatus } = useApiTimeEntryStopPending()

  const loading = computed(() => {
    return timeEntryCreateStatus.value === 'pending' || timeEntryStopStatus.value === 'pending'
  })

  async function start(quickStartForTaskId?: Task['id']) {
    timeEntryToCreate.value = {
      taskId: quickStartForTaskId || timeTrackerModel.value.taskId!,
      notes: quickStartForTaskId ? undefined : timeTrackerModel.value.notes,
    }
    await createTimeEntry()

    timeEntryToCreate.value = {} as ApiTimeEntryCreatePendingParams

    if (timeEntryCreateData.value) {
      latestTimeEntry.value = {
        projectId: timeEntryCreateData.value.data.projectId,
        taskListId: timeEntryCreateData.value.data.task.taskListId,
        taskId: timeEntryCreateData.value.data.taskId,
      }
      latestProject.value = timeEntryCreateData.value.data.project
      latestTask.value = timeEntryCreateData.value.data.task
      activeTimeEntry.value = timeEntryCreateData.value.data
      timeTrackerModel.value = {
        projectId: activeTimeEntry.value.projectId,
        taskId: activeTimeEntry.value.taskId,
        taskListId: activeTimeEntry.value.task.taskListId,
        notes: activeTimeEntry.value.notes,
      }
      if (recentTasks.value.expanded) {
        const createdTask: Task<'withProject'> = {
          ...timeEntryCreateData.value.data.task,
          taskList: { ...timeEntryCreateData.value.data.taskList, project: timeEntryCreateData.value.data.project },
        }
        recentTasks.value.tasks = [createdTask, ...recentTasks.value.tasks.filter(task => task.id !== activeTimeEntry.value?.taskId)].slice(0, 5)
      }

      refreshInitialModelState()
      closeModal()
      // Refetch active time entry event in the active calendar
      if (activeCalendar.value)
        activeCalendar.value.getApi().getEventSourceById('activeTimeEntry')?.refetch()
      message.success('Zeiteintrag erfolgreich gestartet!')
    }
    else if (timeEntryCreateError.value && !modalInstance.value) {
      openModal()
    }
    else if (timeEntryCreateError.value) {
      message.error(timeEntryCreateError.value.data.message)
    }

    return { data: timeEntryCreateData, error: timeEntryCreateError }
  }

  async function stop() {
    await stopPendingTimeEntry()

    if (timeEntryStopData.value) {
      activeTimeEntry.value = undefined
      message.success('Zeiteintrag erfolgreich gespeichert!')

      // Refetch all events in the active calendar
      if (activeCalendar.value)
        activeCalendar.value.getApi().refetchEvents()
    }
    else if (timeEntryStopError.value) {
      activeTimeEntry.value = undefined
      // Refetch all events in the active calendar
      if (activeCalendar.value)
        activeCalendar.value.getApi().refetchEvents()

      message.error(timeEntryStopError.value.data.message, {
        duration: 8000,
        closable: true,
      })

      if (timeEntryStopError.value.statusCode === 403 && timeEntryStopError.value.data.data) {
        const invalidTimeEntry = timeEntryStopError.value.data.data as TimeEntry

        timeTrackerModel.value.projectId = invalidTimeEntry.projectId
        await Promise.all([fetchProjects(), fetchEmployees(), fetchTaskLists()])

        modal.create({
          title: 'Zu speichernden Zeiteintrag korrigieren',
          preset: 'dialog',
          showIcon: false,
          class: '!w-3xl',
          maskClosable: false,
          content: () => h(TimeEntryModal, {
            projectId: invalidTimeEntry.projectId,
            taskId: invalidTimeEntry.taskId,
            startAt: invalidTimeEntry.startAt,
            endAt: invalidTimeEntry.endAt || new Date(),
            notes: invalidTimeEntry.notes,
            employees: employeesData.value?.data || [],
            projects: projectsData.value?.data || [],
            taskLists: taskListsData.value?.data,
          }),
        })
      }
    }

    return { data: timeEntryStopData, error: timeEntryStopError }
  }

  async function updateActiveTimeEntry(params: ApiTimeEntryUpdateParams) {
    activeTimeEntryUpdate.value = params
    await updateTimeEntry()

    if (timeEntryUpdateData.value) {
      activeTimeEntry.value = timeEntryUpdateData.value.data

      latestTimeEntry.value = {
        projectId: timeEntryUpdateData.value.data.projectId,
        taskListId: timeEntryUpdateData.value.data.task.taskListId,
        taskId: timeEntryUpdateData.value.data.taskId,
      }

      latestProject.value = timeEntryUpdateData.value.data.project
      latestTask.value = timeEntryUpdateData.value.data.task

      // Refetch active time entry event in the active calendar
      if (activeCalendar.value)
        activeCalendar.value.getApi().getEventSourceById('activeTimeEntry')?.refetch()

      message.success('Zeiteintrag erfolgreich aktualisiert!')
    }

    return {
      data: timeEntryUpdateData,
      error: timeEntryUpdateError,
    }
  }

  async function loadInitialTimeEntry() {
    await fetchPending()
    if (initialTimeEntry.value) {
      activeTimeEntry.value = initialTimeEntry.value.data
      latestProject.value = initialTimeEntry.value.data.project
      latestTask.value = initialTimeEntry.value.data.task
      timeTrackerModel.value = {
        notes: activeTimeEntry.value.notes,
        projectId: activeTimeEntry.value.projectId,
        taskListId: activeTimeEntry.value.task.taskListId,
        taskId: activeTimeEntry.value.taskId,
      }
      refreshInitialModelState()
    }
  }

  loadInitialTimeEntry()
  /* End of fetching */

  /* Start of time tracking */
  const timestamp = useTimestamp()

  const timeElapsed = computed(() => {
    return activeTimeEntry.value && activeTimeEntry.value.startAt ? timestamp.value - activeTimeEntry.value.startAt.getTime() : 0
  })

  const isRunning = computed(() => {
    return isDefined(activeTimeEntry)
  })
  /* End of time tracking */

  return {
    isRunning,
    timeElapsed,
    activeTimeEntry,
    updateActiveTimeEntry,
    refreshInitialModelState,
    resetModelState,
    edited,
    latestProject,
    latestTask,
    start,
    stop,
    timeTrackerModel,
    openModal,
    closeModal,
    activeCalendar,
    loading,
    timeEntryPendingError,
    timeEntryCreateError,
    timeEntryUpdateError,
    timeEntryStopError,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useTimeTracker, import.meta.hot))
