<script setup lang="ts">
import type { SelectGroupOption, SelectOption } from 'naive-ui'
import { subject } from '@casl/ability'
import { format } from 'date-fns'
import { NAvatar, useModal, useModalReactiveList } from 'naive-ui'
import type { AuthorizationAction } from '~/types/authorization.type'
import { type CalendarEventInput, timeEntriesEventsSourceId, timeEntryCloneEventId } from '~/types/calendar.type'
import type { Employee } from '~/types/employee.type'
import type { Project } from '~/types/project.type'
import type { Task } from '~/types/task.type'
import type { TaskList } from '~/types/taskList.type'
import type { TimeEntry } from '~/types/timeEntry.type'
import TimeEntryDuration from './timesheets/TimeEntryDuration.vue'

const props = defineProps({
  projectId: {
    type: Number as PropType<Project['id']>,
    default: undefined,
  },
  taskId: {
    type: Number as PropType<Task['id']>,
    default: undefined,
  },
  startAt: {
    type: Date,
    default: new Date(),
  },
  endAt: {
    type: Date,
    default: undefined,
  },
  timeEntry: {
    type: Object as PropType<TimeEntry>,
    default: undefined,
  },
  taskLists: {
    type: Array as PropType<TaskList<'withTasks'>[]>,
    default: () => [],
  },
  projects: {
    type: Array as PropType<Project<'withLeads' | 'withClient'>[]>,
    default: () => [],
  },
  employees: {
    type: Array as PropType<Array<Employee>>,
    default: () => [],
  },
  notes: {
    type: String,
    default: undefined,
  },
})

const lastManualTimeEntry = useLastManualTimeEntry()

const modal = useModal()
const modals = useModalReactiveList()
const dialog = useDialog()
const message = useMessage()
const loadingBar = useNaiveLoadingBar()
const { activeCalendar } = storeToRefs(useTimeTracker())
const { user } = storeToRefs(useUserStore())
const { timeEntriesFilter } = storeToRefs(useTimeEntries())

if (activeCalendar.value?.getApi())
  activeCalendar.value?.getApi().getEventById(timeEntryCloneEventId.toString())?.remove()

const currentModal = computed(() => {
  return modals.value.length ? modals.value[modals.value.length - 1] : undefined
})

/* Start of form */

interface TimeEntryModalForm {
  projectId: Project['id'] | null
  employeeId: ApiTimeEntryCreateParams['employeeId'] | null
  taskId: ApiTimeEntryCreateParams['taskId'] | null
  notes: ApiTimeEntryCreateParams['notes']
  startAt: number
  endAt: number
  isBilled: ApiTimeEntryCreateParams['isBilled']
}

// TODO: remove once projects and tasks can no longer be deleted when having time entries
let initialProjectId = null
if (props.projectId)
  initialProjectId = props.projectId
else if (lastManualTimeEntry.value && props.projects.some(project => project.id === lastManualTimeEntry.value.projectId))
  initialProjectId = lastManualTimeEntry.value.projectId

let initialTaskId = null
if (props.taskId)
  initialTaskId = props.taskId
else if (lastManualTimeEntry.value && props.taskLists.some(({ tasks }) => tasks.some(task => task.id === lastManualTimeEntry.value.taskId)))
  initialTaskId = lastManualTimeEntry.value.taskId

const model = ref<TimeEntryModalForm>(props.timeEntry
  ? {
      projectId: props.timeEntry.projectId,
      employeeId: props.timeEntry.employeeId,
      taskId: props.timeEntry.taskId,
      startAt: props.timeEntry.startAt.getTime(),
      endAt: props.timeEntry.endAt?.getTime() || Date.now(),
      notes: props.timeEntry.notes,
      isBilled: props.timeEntry.isBilled,
    }
  : {
      projectId: initialProjectId || null,
      employeeId: timeEntriesFilter.value.employeeId || user.value?.employee.id || null,
      taskId: initialTaskId || null,
      notes: props.notes || '',
      startAt: props.startAt?.getTime() || Date.now(),
      endAt: props.endAt?.getTime() || props.startAt.getTime() + 3600000,
      isBilled: false,
    })

const { edited, reset } = useFormModel(model)

/* End of form */

/* Start of projects */

const selectedProject = computed<Project<'withLeads'> | undefined>(() => {
  return props.projects.find(project => project.id === (props.projectId ?? model.value.projectId))
})

function switchProject() {
  // clear dependent selections on project select
  model.value.taskId = null
  model.value.employeeId = user.value?.employee.id || 0
  blurFormElement()
}

/* End of projects */

/* Start of permissions */

const action: AuthorizationAction = props.timeEntry ? 'update' : 'create'
const { can } = useAppAbility()

const timeEntrySubject = computed(() => {
  return subject('time-entry', {
    ...(props.timeEntry || model.value),
    project: selectedProject.value,
  })
})

/* End of permissions */

/* Start of employees */

type EmployeeOption = SelectOption & {
  avatar: string | null
  initials: string
}

const employeeOptions = computed<EmployeeOption[]>(() => {
  return props.employees.map((employee) => {
    return {
      label: `${employee.forename} ${employee.surname}`,
      value: employee.id,
      avatar: employee.imagePath,
      initials: `${employee.forename[0]}${employee.surname[0]}`,
    }
  })
})

function renderEmployeeLabel(option: EmployeeOption) {
  return h('div', { class: 'flex items-center space-x-1 py-1' }, [
    h(NAvatar, {
      src: option.avatar || '/placeholder',
      round: true,
      size: 'small',
    }, {
      fallback: () => h('div', { class: 'w-full flex items-center justify-center' }, `${option.initials}`),
    }),
    h('span', {}, { default: () => option.label }),
  ])
}

/* End of employees */

/* Start of tasks */

const taskListsFilter = computed<ApiTaskListGetListParams & { withTasks: true, withCompleted: false }>(() => {
  return {
    projectId: model.value.projectId || 0,
    withTasks: true,
    withCompleted: false,
  }
})

const { data: taskListsData, refresh: fetchTaskLists, status: taskListsFetchStatus } = useApiTaskListGetList(taskListsFilter, { immediate: false })

const taskLists = computed<TaskList[]>(() => {
  return taskListsData.value ? taskListsData.value.data : props.taskLists
})

const taskOptions = computed<Array<SelectOption | SelectGroupOption>>(() => {
  return taskLists.value.map((taskList) => {
    return {
      type: 'group',
      label: taskList.name,
      key: `${taskList.projectId}-${taskList.id}`,
      children: taskList.tasks.map((task) => {
        return {
          label: task.name,
          value: task.id,
          key: `${taskList.projectId}-${taskList.id}-${task.id}`,
        }
      }),
    }
  })
})

const selectedTask = computed<Task<undefined> | undefined>(() => {
  for (const taskList of taskLists.value) {
    const task = taskList.tasks.find(task => task.id === model.value.taskId)
    if (task)
      return task
  }
  return undefined
})

/* End of tasks */

/* Start of submit */

const timeEntryCreate = ref<ApiTimeEntryCreateParams>({} as ApiTimeEntryCreateParams)
const timeEntryUpdate = ref<ApiTimeEntryUpdateParams>({} as ApiTimeEntryUpdateParams)

const { error: createTimeEntryError, data: timeEntryCreateData, execute: createTimeEntry, status: createStatus } = useApiTimeEntryCreate(timeEntryCreate)
const { error: updateTimeEntryError, data: timeEntryUpdateData, execute: updateTimeEntry, status: updateStatus } = useApiTimeEntryUpdateById(props.timeEntry?.id || 0, timeEntryUpdate)

const { formRef, rules, onSubmit, apiErrors } = useNaiveForm(model)
const { apiErrorMessages, clearApiErrors } = useApiErrors<ApiTimeEntryCreateParams>(apiErrors, createTimeEntryError, updateTimeEntryError)

rules.value = {
  projectId: [
    {
      required: true,
      message: 'Projekt auswählen',
      trigger: ['blur', 'input'],
      type: 'number',
    },
  ],
  taskId: [
    {
      key: 'taskId',
      validator: () => !apiErrors.value.taskId,
      message: () => apiErrorMessages.value.taskId!,
      trigger: ['input', 'blur'],
    },
  ],
  startAt: [
    {
      required: true,
      message: 'Startzeit auswählen',
      type: 'number',
      trigger: ['blur', 'input'],
    },
    {
      validator: () => !apiErrors.value.startAt,
      message: () => apiErrorMessages.value.startAt!,
      trigger: ['input', 'blur'],
    },
  ],
  endAt: [
    {
      required: true,
      message: 'Endzeit auswählen',
      type: 'number',
      trigger: ['blur', 'input'],
    },
    {
      validator: (_rule, val) => val > model.value.startAt,
      message: () => 'Der Endzeitpunkt muss nach dem Start liegen',
      trigger: ['blur', 'input'],
    },
    {
      validator: () => !apiErrors.value.endAt,
      message: () => apiErrorMessages.value.endAt!,
    },
  ],
  employeeId: [
    {
      validator: () => !apiErrors.value.employeeId,
      message: () => apiErrorMessages.value.employeeId!,
      trigger: ['input', 'blur'],
    },
  ],
}

const { timeEntries } = storeToRefs(useTimeEntries())

function submitForm() {
  if ((props.timeEntry && !edited.value) || createStatus.value === 'pending' || updateStatus.value === 'pending')
    return

  clearApiErrors()
  onSubmit(handleSubmit)
}

async function handleSubmit() {
  if (!props.timeEntry) {
    timeEntryCreate.value = {
      employeeId: (model.value.employeeId || user.value?.employee.id)!,
      startAt: new Date(model.value.startAt),
      endAt: new Date(model.value.endAt),
      taskId: model.value.taskId!,
      notes: model.value.notes,
      isBilled: can('create', timeEntrySubject.value, 'isBilled') ? model.value.isBilled : undefined,
    }

    await createTimeEntry()

    if (createTimeEntryError.value) {
      message.error(createTimeEntryError.value.data.message)
    }
    else if (timeEntryCreateData.value) {
      const timeEntry = timeEntryCreateData.value.data
      timeEntries.value = [...timeEntries.value, timeEntry]
      if (activeCalendar.value) {
        const event: CalendarEventInput = {
          timeEntry,
          title: timeEntry.task.name,
          start: timeEntry.startAt,
          end: timeEntry.endAt!,
          id: `${timeEntry.id}`,

        }
        activeCalendar.value.getApi().addEvent(event, timeEntriesEventsSourceId.toString())
      }

      lastManualTimeEntry.value = {
        projectId: timeEntry.projectId,
        taskId: timeEntry.taskId,
        durationSec: undefined,
      }

      modal.destroyAll()
      message.success('Zeiteintrag erfolgreich erstellt!')
    }
  }
  else {
    timeEntryUpdate.value = {
      employeeId: can('update', timeEntrySubject.value, 'employeeId') ? (model.value.employeeId || user.value?.employee.id)! : undefined,
      startAt: can('update', timeEntrySubject.value, 'startAt') ? new Date(model.value.startAt) : undefined,
      endAt: can('update', timeEntrySubject.value, 'endAt') ? new Date(model.value.endAt) : undefined,
      taskId: can('update', timeEntrySubject.value, 'taskId') ? model.value.taskId! : undefined,
      notes: can('update', timeEntrySubject.value, 'notes') ? model.value.notes : undefined,
      isBilled: can('update', timeEntrySubject.value, 'isBilled') ? model.value.isBilled : undefined,
    }

    await updateTimeEntry()

    if (updateTimeEntryError.value) {
      message.error(updateTimeEntryError.value.data.message)
    }
    else if (timeEntryUpdateData.value) {
      timeEntries.value = [...timeEntries.value.filter(timeEntry => timeEntry.id !== props.timeEntry?.id), timeEntryUpdateData.value.data]
      if (activeCalendar.value) {
        const timeEntry = timeEntryUpdateData.value.data
        const event = activeCalendar.value.getApi().getEventById(`${props.timeEntry?.id}`)

        event?.setExtendedProp('timeEntry', timeEntry)
        event?.setStart(timeEntry.startAt)
        event?.setEnd(timeEntry.endAt)
      }

      lastManualTimeEntry.value = {
        projectId: timeEntryUpdateData.value.data.projectId,
        taskId: timeEntryUpdateData.value.data.taskId,
        durationSec: undefined,
      }

      modal.destroyAll()
      message.success('Zeiteintrag erfolgreich aktualisiert!')
    }
  }
}

/* End of submit */

/* Start of task creation */
async function onTaskCreated(task: Task<undefined>) {
  await fetchTaskLists()
  model.value.taskId = task.id
  formRef.value?.validate(undefined, rule => rule.key === 'taskId')
}
/* End of task creation */

/* Start of grid related vars */

const canSelectEmployeeId = computed(() => {
  return (can(action, timeEntrySubject.value, 'employeeId') || can('read', 'time-entry')) && model.value.projectId != null
})

/* End of grid related vars */

/* Start of delete dialog */

const timeEntryToDelete = ref<TimeEntry['id']>(props.timeEntry?.id || 0)
const { error: deleteTimeEntryError, execute: executeDelete } = useApiTimeEntryDeleteById(timeEntryToDelete)
const deleteLoading = ref(false)

function openTimeEntryDeleteDialog() {
  const timeEntry = props.timeEntry
  if (!timeEntry)
    return

  dialog.create({
    title: 'Wirklich löschen?',
    content: () => h('div', ['Bist du dir sicher, dass du den Zeiteintrag am ', h('span', { class: 'font-semibold' }, {
      default: () => `${format(timeEntry.startAt, 'dd.MM.yy')} von ${format(timeEntry.startAt, 'HH:mm')} bis ${format(timeEntry.endAt!, 'HH:mm')}`,
    }), ' löschen möchtest?']),
    positiveText: 'Löschen',
    negativeText: 'Nicht löschen',
    onPositiveClick: () => {
      deleteTimeEntry()
    },
  })
}

async function deleteTimeEntry() {
  loadingBar.start()
  deleteLoading.value = true
  await executeDelete()
  loadingBar.finish()
  deleteLoading.value = false

  if (!deleteTimeEntryError.value) {
    if (modals.value.length)
      modals.value[modals.value.length - 1].destroy()
    message.success('Zeiteintrag erfolgreich gelöscht!')
    timeEntries.value = timeEntries.value.filter(timeEntry => timeEntry.id !== timeEntryToDelete.value)
    if (activeCalendar.value)
      activeCalendar.value.getApi().getEventById(`${timeEntryToDelete.value}`)?.remove()
  }
  else {
    message.error(deleteTimeEntryError.value.data.message)
  }
}

/* End of delete dialog */

onMounted(() => {
  if (currentModal.value) {
    const currentTitle = currentModal.value.title
    currentModal.value.title = () => h('div', { class: 'flex justify-between w-full' }, [
      h('span', undefined, currentTitle),
      h(TimeEntryDuration, {
        startAt: model.value.startAt,
        endAt: model.value.endAt,
      }),
    ])
  }
})

const submitFocusDiv = ref<HTMLDivElement | null>(null)

function blurFormElement() {
  nextTick(() => submitFocusDiv.value?.focus())
}
</script>

<template>
  <NForm
    ref="formRef"
    :model="model"
    :rules="rules"
    @submit.prevent="submitForm"
  >
    <div
      ref="submitFocusDiv"
      class="focus:border-none focus:outline-none"
      tabindex="0"
      @keydown.enter="submitForm"
    >
      <div class="grid auto-rows-max grid-cols-1 gap-x-6 md:grid-cols-[250px_1fr]">
        <div class="grid row-span-3 grid-rows-subgrid items-start">
          <NFormItem label="Projekt" path="projectId" required>
            <ProjectSelect
              v-model="model.projectId"
              :projects="projects"
              show-recent-projects-group
              :disabled="!can(action, timeEntrySubject, 'projectId')"
              @update:value="switchProject"
              @keydown.enter.stop
            />
          </NFormItem>
          <NFormItem label="Aufgabe" path="taskId" required>
            <TaskSelect
              v-model:value="model.taskId"
              :project-id="model.projectId"
              :task-lists="taskLists"
              :select-props="{
                disabled: !model.projectId,
                loading: taskListsFetchStatus === 'pending',
              }"
              :show-task-add-action="model.projectId !== null"
              :disabled="!can(action, timeEntrySubject, 'taskId')"
              @task-created="onTaskCreated"
              @keydown.enter.stop
              @update:value="() => blurFormElement()"
            />
          </NFormItem>
          <NFormItem label="Mitarbeiter:in" path="employeeId">
            <NSelect
              v-model:value="model.employeeId"
              :render-label="renderEmployeeLabel"
              :options="employeeOptions"
              :disabled="!canSelectEmployeeId || !model.projectId || !can(action, timeEntrySubject, 'employeeId')"
              placeholder="Mitarbeiter:in auswählen"
              @keydown.enter.stop
              @update:value="() => blurFormElement()"
            />
          </NFormItem>
        </div>
        <div class="grid row-span-3 grid-rows-subgrid items-start">
          <div class="flex items-start space-x-4">
            <NFormItem label="Startzeit" path="startAt" required class="grow">
              <NDatePicker
                v-model:value="model.startAt"
                class="w-full"
                type="datetime"
                update-value-on-close
                :disabled="!can(action, timeEntrySubject, 'startAt')"
                @keydown.enter.stop
                @confirm="() => blurFormElement()"
              />
            </NFormItem>
            <NFormItem label="Endzeit" path="endAt" required class="grow">
              <NDatePicker
                v-model:value="model.endAt"
                class="w-full"
                type="datetime"
                update-value-on-close
                @keydown.enter.stop
                @confirm="() => blurFormElement()"
              />
            </NFormItem>
          </div>
          <NFormItem v-if="can(action, timeEntrySubject, 'notes')" label="Anmerkungen" path="notes">
            <NInput
              v-model:value="model.notes"
              type="textarea"
              :options="taskOptions"
              placeholder="Optionale Anmerkungen hinzufügen"
              class="h-full"
              :resizable="false"
              filterable
              @keydown.enter.stop
            />
          </NFormItem>
          <NFormItem
            v-if="can(action, timeEntrySubject, 'isBilled')
              && (selectedProject?.billingType === 'times_and_material')
              && selectedTask?.isBillable" label="Abgerechnet" path="isBilled"
          >
            <NSwitch
              v-model:value="model.isBilled"
              size="large"
              @keydown.enter.stop
            >
              <template #checked>
                Abgerechnet
              </template>
              <template #unchecked>
                Nicht abgerechnet
              </template>
            </NSwitch>
          </NFormItem>
        </div>
      </div>
      <div class="flex justify-end -my-1 sm:my-0">
        <NButtonGroup class="flex-wrap sm:flex-nowrap">
          <NButton
            attr-type="submit"
            class="my-1 sm:my-0"
            type="primary"
            :disabled="(timeEntry && !edited) || createStatus === 'pending' || updateStatus === 'pending'"
            :loading="createStatus === 'pending' || updateStatus === 'pending'"
          >
            {{ timeEntry ? 'Aktualisieren' : 'Eintrag hinzufügen' }}
          </NButton>
          <NButton
            class="my-1 sm:my-0"
            secondary
            :disabled="(timeEntry && !edited) || createStatus === 'pending' || updateStatus === 'pending'"
            :loading="createStatus === 'pending' || updateStatus === 'pending'"
            @click="() => timeEntry ? reset() : modal.destroyAll()"
          >
            {{ timeEntry ? 'Zurücksetzen' : 'Abbrechen' }}
          </NButton>
          <NButton
            v-if="timeEntry && can('delete', subject('time-entry', timeEntry))"
            class="my-1 sm:my-0"
            :loading="deleteLoading"
            secondary
            type="error"
            @click="openTimeEntryDeleteDialog"
          >
            Löschen
          </NButton>
        </NButtonGroup>
      </div>
    </div>
  </NForm>
</template>
